import React from 'react'
import { graphql, Link } from 'gatsby'
import { Layout, CardImage } from '../components'
import { Helmet } from 'react-helmet'
import { COLORS } from '../utils'
import { getSvgIcon } from '../utils/helpers'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import { useMinWidth } from '../utils/hooks'
import { BREAK_POINTS } from '../utils/theme'

const PostLink = ({ post }) => {
  const [isHovered, setHovered] = React.useState(false)

  const handleMouseOver = React.useCallback(() => setHovered(true), [])
  const handleMouseLeave = React.useCallback(() => setHovered(false), [])

  const isDesktop = useMinWidth(BREAK_POINTS.large)
  const hasIcon = Boolean(getSvgIcon({ name: post.frontmatter.category }))
  const isJS = /javascript/gi.test(post.frontmatter.category)

  return (
    <li>
      <article onMouseLeave={handleMouseLeave} onMouseOver={handleMouseOver}>
        <Link
          to={post.frontmatter.path}
          style={{ fontSize: '1.25em', textDecoration: 'none' }}
        >
          <div
            style={{
              position: 'relative',
              borderRadius: 20,
              overflow: 'hidden',
            }}
          >
            <CardImage hovered={isHovered} src={post.frontmatter.hero.image} />
            <CSSTransition
              in={isDesktop && isHovered}
              timeout={300}
              classNames={!isJS ? 'slide-in-spin' : 'slide-in-pulse'}
              unmountOnExit
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 2,
                }}
              >
                <img
                  width={100}
                  src={getSvgIcon({ name: post.frontmatter.category })}
                  alt=""
                />
              </div>
            </CSSTransition>
            <CSSTransition
              in={isDesktop && hasIcon && isHovered}
              timeout={300}
              classNames="fade-in"
              unmountOnExit
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  zIndex: 1,
                }}
              />
            </CSSTransition>
          </div>
          <div style={{ padding: '1rem 0' }}>
            <h2 style={{ margin: 0 }}>{post.frontmatter.title}</h2>
            <time style={{ fontSize: 14 }} date={post.frontmatter.date}>
              {post.frontmatter.date}
            </time>
            <p
              style={{
                textDecoration: 'none',
                color: 'grey',
                fontSize: 18,
                lineHeight: '24px',
              }}
            >
              {post.excerpt}{' '}
              <span
                style={{
                  fontSize: '.75rem',
                  color: COLORS.lightBlue,
                  display: 'inline-block',
                }}
              >
                {' '}
                /{' '}
                {post.frontmatter.category
                  ? post.frontmatter.category.toLowerCase()
                  : ''}
              </span>
            </p>
          </div>
        </Link>
      </article>
    </li>
  )
}

const PostList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2rem;

  @media (max-width: 400px) {
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  }
`

export default ({
  data: {
    allMarkdownRemark: { edges },
    site: { siteMetadata },
  },
}) => {
  const _title = `${siteMetadata.title} | blog`
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <Layout>
      <Helmet>
        <title>{siteMetadata.title} | blog</title>
        <meta name="twitter:title" content={_title} />
        <meta property="og:title" content={_title} />
      </Helmet>
      <div
        style={{
          padding: '1.45rem 1.0875rem',
          width: '100%',
          maxWidth: 960,
          margin: '0 auto',
        }}
      >
        <h1 style={{ color: '#4b4b4b' }}>Blog</h1>
        <PostList>{Posts}</PostList>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/blog/" } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          id
          frontmatter {
            title
            date(formatString: "MM/DD/YYYY")
            path
            category
            hero {
              alt
              image
            }
          }
        }
      }
    }
  }
`
