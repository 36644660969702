import React from 'react'

export function useWindowResize(callback) {
  const _callback = React.useCallback(callback, [])
  React.useEffect(() => {
    window.addEventListener('resize', _callback)
    return () => {
      window.removeEventListener('resize', _callback)
    }
  }, [_callback])
}

export function useViewportWidth() {
  const [viewportWidth, setViewportWidth] = React.useState(() => {
    return typeof window !== 'undefined'
      ? window.document.body.getBoundingClientRect().width
      : null
  })

  useWindowResize(() =>
    setViewportWidth(
      typeof window !== 'undefined'
        ? window.document.body.getBoundingClientRect().width
        : null
    )
  )

  return viewportWidth
}

export function useMinWidth(minWidth) {
  const currentWidth = useViewportWidth()
  return currentWidth >= minWidth
}

export function useMaxWidth(useMaxWidth) {
  const currentWidth = useViewportWidth()
  return currentWidth <= useMaxWidth
}
